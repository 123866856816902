import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg overflow-hidden `; 
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export default ({
  headingText = "Découvrez nos formations",
  // posts = [
  //   {
  //     imageSrc:
  //       "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  //     category: "Travel Tips",
  //     date: "April 21, 2020",
  //     title: "Safely Travel in Foreign Countries",
  //     description:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  //     url: "https://timerse.com",
  //     featured: true
  //   },
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost(),
  //   getPlaceholderPost()
  // ]

  posts = [
    {
      "imageSrc": "https://images.unsplash.com/photo-1612178991541-b48cc8e92a4d?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "category": "Économie et Affaires",
      "date": "28 mars 2024",
      "title": "Gestion financière pour les entrepreneurs",
      "description": "Apprenez à gérer vos finances efficacement pour faire prospérer votre entreprise.",
      "url": "http://localhost:3000/cours/gestion-financiere-entrepreneurs",
      "featured": true
    },
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1565793298595-6a879b1d9492?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Logistique et Transport",
      "28 mars 2024",
      "Gestion de la chaîne d'approvisionnement",
      "Découvrez les principes de base de la gestion de la chaîne d'approvisionnement et son importance pour les entreprises.",
      "http://localhost:3000/cours/gestion-chaine-approvisionnement"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1530971013997-e06bb52a2372?q=80&w=3392&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Comptatibilité et Finance",
      "28 mars 2024",
      "Comptabilité pour non-comptables",
      "Apprenez les bases de la comptabilité pour mieux gérer vos finances personnelles et professionnelles.",
      "http://localhost:3000/cours/comptabilite-non-comptables"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Comptaibilité et Finance Avancée",
      "28 mars 2024",
      "Analyse financière et prise de décision",
      "Découvrez des techniques d'analyse financière pour prendre des décisions éclairées dans votre entreprise.",
      "http://localhost:3000/cours/analyse-financiere-prise-decision"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1602066556450-62f988867a31?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Douane et Commerce International",
      "28 mars 2024",
      "Réglementation douanière et commerce international",
      "Apprenez les règles et réglementations douanières pour faciliter le commerce international.",
      "http://localhost:3000/cours/reglementation-douaniere-commerce-international"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1677756119517-756a188d2d94?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Informatique et Technologie",
      "28 mars 2024",
      "L'intelligence artificielle dans votre entreprise",
      "Découvrez comment l'intelligence artificielle peut améliorer votre entreprise et vos processus commerciaux.",
      "http://localhost:3000/cours/utiliser-intelligence-artificielle-entreprise"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1477013743164-ffc3a5e556da?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "Informatique et Technologie",
      "28 mars 2024",
      "Développer votre visibilité en ligne",
      "Apprenez des stratégies pour améliorer votre visibilité en ligne et attirer plus de clients sur Internet.",
      "http://localhost:3000/cours/developper-visibilite-ligne"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Stratégies de gestion du stress",
      "Découvrez des techniques pour réduire le stress et améliorer votre bien-être mental et physique.",
      "http://localhost:3000/cours/strategies-gestion-stress"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Développement personnel et leadership",
      "Apprenez à développer votre leadership personnel pour réussir dans votre vie professionnelle et personnelle.",
      "http://localhost:3000/cours/developpement-personnel-leadership"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Stratégies de gestion du temps",
      "Découvrez des techniques pour améliorer votre gestion du temps et augmenter votre productivité.",
      "http://localhost:3000/cours/strategies-gestion-temps"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Leadership et communication",
      "Développez vos compétences en leadership et en communication pour réussir dans votre carrière.",
      "http://localhost:3000/cours/leadership-communication"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Gestion du stress et bien-être",
      "Apprenez des stratégies pour gérer le stress et améliorer votre bien-être au quotidien.",
      "http://localhost:3000/cours/gestion-stress-bien-etre"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Développement personnel et confiance en soi",
      "Découvrez des techniques pour renforcer votre confiance en vous et atteindre vos objectifs personnels.",
      "http://localhost:3000/cours/developpement-personnel-confiance-soi"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Stratégies de communication efficace",
      "Améliorez vos compétences en communication pour mieux interagir avec les autres et réussir dans votre vie professionnelle.",
      "http://localhost:3000/cours/strategies-communication-efficace"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Gestion du changement et résilience",
      "Apprenez à gérer le changement et à développer votre résilience pour faire face aux défis de la vie.",
      "http://localhost:3000/cours/gestion-changement-resilience"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Stratégies de gestion du stress",
      "Découvrez des techniques pour réduire le stress et améliorer votre bien-être mental et physique.",
      "http://localhost:3000/cours/strategies-gestion-stress"
    ),
    getPlaceholderPost(
      "https://images.unsplash.com/photo-1517849845537-4d257902454a?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
      "Développement Personnel",
      "12 avril 2024",
      "Développement personnel et leadership",
      "Apprenez à développer votre leadership personnel pour réussir dans votre vie professionnelle et personnelle.",
      "http://localhost:3000/cours/developpement-personnel-leadership"
    ),
  ]
  
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post className="group" as="a"
                href={"#"} 
                // href={post.url}
                >
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category>{post.category}</Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title>{post.title}</Title>
                    {post.featured && post.description && <Description>{post.description}</Description>}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
          {visible < posts.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost = (imageSrc, category, date, title, description, url) => ({
  imageSrc: imageSrc || "https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
  category: category ||  "Travel Guide",
  date: date || "April 19, 2020",
  title: title || "Visit the beautiful Alps in Switzerland",
  description: description || 
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: url || "https://reddit.com"
});
